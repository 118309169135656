import React, { createContext, useEffect, useState } from "react";

const userContext = createContext();

export function UserContextProvider({ children }) {
  const [currentUserData, setCurrentUserData] = useState(() => {
    // eslint-disable-next-line no-undef
    const storedData = localStorage.getItem("userData");
    return storedData
      ? JSON.parse(storedData)
      : {
          createdAt: "",
          objectId: "",
          sessionToken: "",
          updatedAt: "",

          admin: false,

          email: "",
          emailVerified: false,
          username: "",
          phone: "",
          profilePicture: "",
          walletBalance: 0.0,

          twitter: "",
          instagram: "",
          tiktok: "",
          facebook: "",

          bankName: "",
          accountNumber: "",
          accountName: "",
        };
  });

  useEffect(() => {
    // eslint-disable-next-line no-undef
    localStorage.setItem("userData", JSON.stringify(currentUserData));
  }, [currentUserData]);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const value = { currentUserData, setCurrentUserData, error, setError, success, setSuccess };

  return <userContext.Provider value={value}>{children}</userContext.Provider>;
}

export default userContext;
