
export const formatAmountToIncludeCurrency = (currency, amount) => {
	return currency
		? `${currency} ${Number(amount).toLocaleString(undefined, {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
		  })}`
		: `${Number(amount).toLocaleString(undefined, {
				minimumFractionDigits: 2,
				maximumFractionDigits: 2,
		  })}`;
};

export const onInputAmount = (e) => {
	e.target.value = e.target.value
		.replace(/[^\dA-Z]/g, "")
		.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const onBlurAmount = (e, amount) => {
	e.target.value = formatAmountToIncludeCurrency(
		undefined,
		amount.replace(/,/g, "") || 0
	);
};