import React, { useContext } from "react";
import {
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import userContext from "../../context/UserContext";

function TransactionHistory() {
  const { currentUserData } = useContext(userContext);
  const transactionHistory = currentUserData.transactionHistory || [];

  console.log(currentUserData);
  return (
    <Container maxWidth="lg">
      {currentUserData.username} {currentUserData.objectId}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Date</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Transaction Type</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {transactionHistory
              .sort((a, b) => b.createdAt - a.createdAt)
              .map((transaction, index) => {
                const timestamp = transaction.createdAt;
                const dateObj = new Date(timestamp);
                const dateString = dateObj.toLocaleDateString();
                const timeString = dateObj.toLocaleTimeString();
                return (
                  <TableRow key={index}>
                    <TableCell>{transaction.dateString}</TableCell>
                    <TableCell>{transaction.timeString}</TableCell>
                    <TableCell>{transaction.transactionType}</TableCell>
                    <TableCell>{transaction.amount}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default TransactionHistory;
