import React from "react";
import { Snackbar, Alert } from "@mui/material";

function ErrorSnackbar({ open, message, onClose }) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={5000} // Optional: Set the duration to display
      onClose={onClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert onClose={onClose} severity="error" sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
}

export default ErrorSnackbar;
