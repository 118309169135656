import React, { useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
} from "@mui/material";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Parse from "../api/ApiCOnfig";
import { useNavigate } from "react-router-dom";
import ErrorSnackbar from "../components/ErrorSnackbar";
import SuccessSnackbar from "../components/SuccessSnackbar";

function Register() {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
    username: "",
    phone: "",
    twitter: "",
    instagram: "",
    tiktok: "",
    facebook: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  console.log(user);

  const handleCreateAccount = async (e) => {
    e.preventDefault();

    const newUser = new Parse.User();
    const Wallet = Parse.Object.extend("Wallet");
    const wallet = new Wallet();

    newUser.set("email", user.email);
    newUser.set("username", user.username);
    newUser.set("password", user.password);
    newUser.set("phone", user.phone);

    try {
      const user = await newUser.signUp();

      // Associate the user with the wallet using a pointer
      const userPointer = Parse.User.createWithoutData(user.id);
      wallet.set("UserData", userPointer);
      await wallet.save();

      console.log("User registered successfully:", user);
      setSuccess("Successfully Logged in");
      navigate("/login");
    } catch (error) {
      console.log("Error while registering user:", error);
      if (error.code === Parse.Error.INVALID_SESSION_TOKEN) {
        try {
          Parse.User.logOut();
        } catch (error) {
          console.log(error);
        }
        // Handle invalid session token error here
        // You might want to handle this based on your application's flow
        setError("Invalid session token. Please try again.");
      } else {
        setError(error.message);
      }
    }
  };

  const handleCloseSnackbar = () => {
    setError(null);
  };
  const handleCloseSuccessSnackbar = () => {
    setSuccess(null);
  };
  return (
    <>
      <Container maxWidth="lg">
        <Box mt={20}>
          <Box component="form" width="100%" onSubmit={handleCreateAccount}>
            <Stack
              spacing={2}
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "100%",
              }}
            >
              <TextField
                type="email"
                name="email"
                value={user.email}
                onChange={handleInputChange}
                label="Email Address"
                required
                sx={{ width: "300px" }}
              />
              <TextField
                type="text"
                name="username"
                value={user.username}
                onChange={handleInputChange}
                label="User Name"
                required
                sx={{ width: "300px" }}
              />
              <TextField
                type="tel"
                name="phone"
                value={user.phone}
                onChange={handleInputChange}
                label="Phone Number"
                required
                sx={{ width: "300px" }}
              />
              <FormControl sx={{ m: 1, width: "300px" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={user.password}
                  onChange={handleInputChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {!showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              <Button type="submit" variant="contained">
                Create Account
              </Button>
              <Button
                type="button"
                variant="text"
                onClick={() => navigate("/")}
              >
                Back
              </Button>
            </Stack>
          </Box>
        </Box>

        <ErrorSnackbar
          open={!!error}
          message={error}
          onClose={handleCloseSnackbar}
        />
        <SuccessSnackbar
          open={!!success}
          message={success}
          onClose={handleCloseSuccessSnackbar}
        />
      </Container>
    </>
  );
}

export default Register;
