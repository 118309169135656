import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "../screens/LandingPage/LandingPage";
import Register from "../auth/Register";
import Login from "../auth/Login";
import Dashboard from "../screens/UserDashboard/Dashboard";
// import { UserContextProvider } from "../context/UserContext";

function RouterPage() {
  return (
    <>
      {/* <UserContextProvider> */}
      <Router>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
        </Routes>
      </Router>
      {/* </UserContextProvider> */}
    </>
  );
}

export default RouterPage;
