import React, { useContext, useState } from "react";
import { useFlutterwave, closePaymentModal } from "flutterwave-react-v3";
import {
  Alert,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { AiFillCloseCircle } from "react-icons/ai";
import userContext from "../context/UserContext";
import ErrorSnackbar from "../components/ErrorSnackbar";
import SuccessSnackbar from "../components/SuccessSnackbar";
import Parse from "../api/ApiCOnfig";
import { onBlurAmount, onInputAmount } from "../utils/Utils";
import { useNavigate } from "react-router-dom";

function FlutterwavePayment({ open, handleClose }) {
  const navigate = useNavigate();
  const [amount, setAmount] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const { currentUserData, setCurrentUserData } = useContext(userContext);

  const newAmount = amount.replace(/,/g, "") || 0;
  const handleCloseSnackbar = () => {
    setError(null);
  };
  const handleCloseSuccessSnackbar = () => {
    setSuccess(null);
  };

  const config = {
    public_key: process.env.REACT_APP_FLWPUBK,
    tx_ref: Date.now(),
    amount: newAmount,
    currency: "NGN",
    payment_options: "card,mobilemoney,ussd",
    customer: {
      email: currentUserData.email,
      phone_number: currentUserData.phone,
      name: currentUserData.username,
    },
    customizations: {
      title: "Play Jackpot",
      description: "Fund Wallet",
      logo: "https://vtlottery.com/sites/default/files/VTT-6407%20%245%20CASH%20BLASTv7deliv_Logo.png",
    },
  };

  const handleFlutterPayment = useFlutterwave(config);

  const HandlePay = async (e) => {
    e.preventDefault();

    if (newAmount <= 500000) {
      const currentUser = Parse.User.current();

      if (currentUser) {
        handleFlutterPayment({
          callback: async (response) => {
            console.log("Response:", response);

            if (response.status === "successful") {
              const userData = currentUser.toJSON();
              console.log(userData.walletBalance);
              const newBalance =
                userData.walletBalance + response.charged_amount;

              
              const newTransaction = {
                transactionType: "credit", // Assuming it's a debit for payment
                amount: newAmount,
              };

              // Get current transaction history and update it
              const transactionHistory = userData.transactionHistory || [];
              transactionHistory.push(newTransaction);

              currentUser.set("walletBalance", newBalance);
              currentUser.set("transactionHistory", transactionHistory);

              // Save the updated wallet and user objects
              try {
                await currentUser.save();
                setCurrentUserData((prevUserData) => ({
                  ...prevUserData,
                  walletBalance: newBalance,
                  transactionHistory: transactionHistory,
                }));
                setAmount("");
                setSuccess("Payment Successful");
                handleClose();
                closePaymentModal();
              } catch (error) {
                setError("Error updating user data");
                console.error("Error updating user data:", error);
              }
            } else if (response.status === "cancelled") {
              setError("Payment Cancelled");
              handleClose();
              closePaymentModal();
            } else if (response.status === "failed") {
              setError("Payment Failed");
              handleClose();
              closePaymentModal();
            }
          },
          onClose: () => {},
        });
      } else {
        setError("Please login again");
        Parse.User.logOut().then(() => {
          localStorage.clear();
          navigate("/login");
        });
      }
    } else {
      setError("Maximum amount of NGN 500,000");
    }
  };

  console.log(newAmount);

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h6" component="h2">
              Fund Wallet
            </Typography>

            <IconButton
              onClick={() => {
                setAmount("");
                handleClose();
              }}
            >
              <AiFillCloseCircle />
            </IconButton>
          </Stack>

          <Box component="form" mt={3}>
            <Stack spacing={3}>
              <Alert severity="info">Minimum deposit is NGN 500</Alert>
              <TextField
                type="tel"
                value={amount}
                onChange={(e) => setAmount(e.target.value)}
                inputMode="numeric"
                placeholder="Enter amount"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Typography>NGN</Typography>
                    </InputAdornment>
                  ),
                }}
                required
                onInput={onInputAmount}
                onBlur={(e) => onBlurAmount(e, amount)}
              />

              <Button
                variant="contained"
                onClick={HandlePay}
                disabled={amount < 500}
              >
                Pay
              </Button>
            </Stack>
          </Box>
        </Box>
      </Modal>
      <ErrorSnackbar
        open={!!error}
        message={error}
        onClose={handleCloseSnackbar}
      />
      <SuccessSnackbar
        open={!!success}
        message={success}
        onClose={handleCloseSuccessSnackbar}
      />
    </>
  );
}

export default FlutterwavePayment;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "8px",
};
