import { useContext, useEffect } from "react";
import RouterPage from "./router/RouterPage";
import userContext from "./context/UserContext";
import ErrorSnackbar from "./components/ErrorSnackbar";
import SuccessSnackbar from "./components/SuccessSnackbar";
import { Navigate } from "react-router-dom";
import Parse from "./api/ApiCOnfig";

function App() {
  const { error, setError, success, setSuccess } = useContext(userContext);

  const handleCloseSnackbar = () => {
    setError(null);
  };
  const handleCloseSuccessSnackbar = () => {
    setSuccess(null);
  };

  useEffect(() => {
    const checkSessionExpiration = async () => {
      const currentUser = Parse.User.current();
      if (currentUser) {
        // const sessionToken = currentUser.getSessionToken();
        const sessionData = await Parse.Session.current();
       
        if (sessionData && sessionData.expirationTime) {
          const expirationTime = sessionData.expirationTime.getTime();
          const currentTime = new Date().getTime();
          console.log("sessionData:", expirationTime);
          if (currentTime > expirationTime) {
            Parse.User.logOut().then(() => {
              localStorage.clear();
              // navigate("/login");
              Navigate("/");
            });
          }
        }
      }
    };

    const interval = setInterval(checkSessionExpiration, 1000 * 60); // Check every minute

    return () => {
      clearInterval(interval); // Clear interval when component unmounts
    };
  }, []);
  return (
    <>
      <RouterPage />
      <ErrorSnackbar
        open={!!error}
        message={error}
        onClose={handleCloseSnackbar}
      />
      <SuccessSnackbar
        open={!!success}
        message={success}
        onClose={handleCloseSuccessSnackbar}
      />
    </>
  );
}

export default App;
