import React from "react";
import { Button, Box, Container, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";

function LandingPage() {
  const navigate = useNavigate();
  return (
    <>
      <Container>
        <Box mt={20}>
          <Stack
            spacing={4}
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Button variant="contained" onClick={() => navigate("/register")}>Register</Button>
            <Button variant="contained" onClick={() => navigate("/login")}>Login</Button>
          </Stack>
        </Box>
      </Container>
    </>
  );
}

export default LandingPage;
