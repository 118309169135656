import React, { useContext, useState } from "react";
import { Button, Container, Stack, TextField, Typography } from "@mui/material";
import userContext from "../context/UserContext";
import ErrorSnackbar from "./ErrorSnackbar";
import Parse from "../api/ApiCOnfig";
import SuccessSnackbar from "./SuccessSnackbar";

function SlotPurchaseForm() {
  const { currentUserData, setCurrentUserData } = useContext(userContext);

  const [quantity, setQuantity] = useState(1);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);

  const amount = 100 * quantity;

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value);
  };
  const handleCloseSnackbar = () => {
    setError(null);
  };
  const handleCloseSuccessSnackbar = () => {
    setSuccess(null);
  };

  //   const handleSubmit = async (event) => {
  //     event.preventDefault();

  //     if (amount <= currentUserData.walletBalance) {
  //       const currentUser = Parse.User.current();

  //       if (currentUser) {
  //         const userData = currentUser.toJSON();
  //         const newBalance = userData.walletBalance - amount;
  //         currentUser.set("walletBalance", newBalance);

  //         // Create a new transaction object
  //         const transaction = {
  //           date: new Date(),
  //           transactionType: "debit", // Assuming it's a debit for payment
  //           amount: amount,
  //         };

  //           // Save the updated wallet and user objects
  //           await Promise.all([currentUser.save()]).then(() => {
  //             setCurrentUserData((prevUserData) => ({
  //               ...prevUserData,
  //               walletBalance: newBalance,
  //             }));
  //             setQuantity(1);
  //             setSuccess("Payment Successful");
  //           });
  //         } else {
  //           setError("Wallet not found");
  //         }
  //       }

  //     console.log("Submitted quantity:", quantity);
  //   };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (amount <= currentUserData.walletBalance) {
      const currentUser = Parse.User.current();

      if (currentUser) {
        const userData = currentUser.toJSON();
        const newBalance = userData.walletBalance - amount;

       

        const newTransaction = {
          transactionType: "debit",
          amount: amount,
        };

        // Get current transaction history and update it
        const transactionHistory = userData.transactionHistory || [];
        transactionHistory.push(newTransaction);

        currentUser.set("walletBalance", newBalance);
        currentUser.set("transactionHistory", transactionHistory);

        // Save the updated wallet and user objects
        try {
          await currentUser.save();
          setCurrentUserData((prevUserData) => ({
            ...prevUserData,
            walletBalance: newBalance,
            transactionHistory: transactionHistory,
          }));
          setQuantity(1);
          setSuccess("Payment Successful");
        } catch (error) {
          setError("Error updating user data");
          console.error("Error updating user data:", error);
        }
      } else {
        setError("Wallet not found");
      }
    }

    console.log("Submitted quantity:", quantity);
  };

  console.log("QuantityAmount:", amount);

  return (
    <Container maxWidth="sm">
      <form onSubmit={handleSubmit}>
        <Stack spacing={2}>
          <TextField
            type="number"
            label="Quantity"
            variant="outlined"
            value={quantity}
            onChange={handleQuantityChange}
            fullWidth
            required
            inputProps={{
              min: 1,
            }}
          />
          <Typography>
            Amount to deduct: NGN {amount.toLocaleString()}
          </Typography>
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Purchase
          </Button>
        </Stack>
      </form>
      <ErrorSnackbar
        open={!!error}
        message={error}
        onClose={handleCloseSnackbar}
      />
      <SuccessSnackbar
        open={!!success}
        message={success}
        onClose={handleCloseSuccessSnackbar}
      />
    </Container>
  );
}

export default SlotPurchaseForm;
