import React, { useContext, useState } from "react";
import {
  Box,
  Button,
  Container,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Parse from "../api/ApiCOnfig";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import userContext from "../context/UserContext";

function Login() {
  const navigate = useNavigate();
  const [user, setUser] = useState({
    username: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState(false);

  const { setCurrentUserData, setError, setSuccess } = useContext(userContext);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await Parse.User.logIn(user.username, user.password);

      Parse.enableEncryptedUser();
      Parse.secret = "posh";

      const currentUser = Parse.User.current();
      const userData = currentUser.toJSON();
      console.log("userLogged:", userData);

      // Update the context with the retrieved user data
      setCurrentUserData(userData);

      setSuccess(
        `Welcome ${userData.username}, Today might be your day to win BIG`
      );
      navigate("/dashboard");
    } catch (error) {
      console.error("Login error:", error);
      if (
        error.message ===
        "Unexpected token 'U', \"U2FsdGVkX1\"... is not valid JSON"
      ) {
        Parse.User.logOut();
        setError("Invalid session token. Please try again.");
      } else {
        setError(error.message);
        // Handle login errors here
      }
    }
  };

  return (
    <>
      <Container maxWidth="lg">
        <Box mt={20}>
          <Box component="form" width="100%" onSubmit={handleLogin}>
            <Stack
              spacing={2}
              direction="column"
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "100%",
              }}
            >
              <TextField
                type="text"
                name="username"
                value={user.username}
                onChange={handleInputChange}
                label="User Name"
                required
                sx={{ width: "300px" }}
              />

              <FormControl sx={{ m: 1, width: "300px" }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={user.password}
                  onChange={handleInputChange}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {!showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              <Button type="submit" variant="contained">
                Sign In
              </Button>
              <Button
                type="button"
                variant="text"
                onClick={() => navigate("/")}
              >
                Back
              </Button>
            </Stack>
          </Box>
        </Box>
      </Container>
    </>
  );
}

export default Login;
