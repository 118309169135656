import React, { useContext, useState } from "react";
import { Box, Button, Container, Stack, Typography } from "@mui/material";
import FlutterwavePayment from "../../flutterwavePayment/FlutterwavePayment";
import Parse from "../../api/ApiCOnfig";
import { useNavigate } from "react-router-dom";
import userContext from "../../context/UserContext";
import SlotPurchaseForm from "../../components/SlotPurchaseForm";
import TransactionHistory from "../../components/transactionHistory/TransactionHistory";

function Dashboard() {
  const navigate = useNavigate();
  const [openPayment, setOpenPayment] = useState(false);
  const { currentUserData } = useContext(userContext);

  
  return (
    <>
      <Container>
        <Box mt={10}>
          <Stack spacing={10}>
            <Stack>
              <Typography sx={{ textTransform: "capitalize" }} align="center">
                {currentUserData.username}
              </Typography>
              <Typography sx={{ textTransform: "capitalize" }} align="center">
                Wallet Balance: NGN{" "}
                {currentUserData.walletBalance.toLocaleString()}
              </Typography>
            </Stack>
            <Button variant="contained" onClick={() => setOpenPayment(true)}>
              Fund Wallet
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                Parse.User.logOut().then(() => {
                  localStorage.clear();
                  navigate("/login");
                });
              }}
            >
              Logout
            </Button>
          </Stack>

          <Box mt={3}>
            <Typography variant="h3" align="center" mb={3}>
              Slot Purchase
            </Typography>
            <SlotPurchaseForm />
          </Box>
        </Box>

        <Box mt={5}>
          <TransactionHistory />
        </Box>
        <FlutterwavePayment
          open={openPayment}
          handleClose={() => setOpenPayment(false)}
        />
      </Container>
    </>
  );
}

export default Dashboard;
